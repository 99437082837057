import React from 'react'
import Image from 'gatsby-image';

import Content from 'components/Content';
import "styles/HeroTitle.scss";

const HeroTitle = ({ fluidImage, title }) => {
  return (
    <section className="heroTitle">
      <Image
        loading="eager"
        fadeIn={false}
        fluid={fluidImage}
        className="hero-image"
      />
      <div className="container">
        {title && (
          <h1>
            <span>{title}</span>
            <span className="dot">.</span>
          </h1>
        )}
      </div>
    </section>
  )
}

export default HeroTitle;
